import http from '@/request'


export const OlaylariGetir = (kisiId) => {
    return http.request({
        url: 'gonullu/OlaylariGetir',
        params: {
            kisiId
        }
    })
}

export const KullaniciIzinleriDuzenle = (kisiId, RetweetIzniVerdiMi, LikeIzniVerdiMi, TweetYazmaIzniVerdiMi) => {
    return http.request({
        url: 'gonullu/KullaniciIzinleriDuzenle',
        method: 'put',
        params: {
            kisiId,
            RetweetIzniVerdiMi,
            LikeIzniVerdiMi,
            TweetYazmaIzniVerdiMi
        }
    })
}
