<template>
    <div>
        <div v-for="olay in olaylar" :key="olay.islemYapilanTweetId">
            <b-alert show>{{olay.yapilanIslem}} İşlemi / {{olay.islemTarihi}}</b-alert>
            <Tweet :id="olay.islemYapilanTweetId"
                   class="mb-5"
                   error-message="Tweet bulunamadı. Silinmiş olabilir mi? Sitede olduğundan emin olun."
                   :options="{ lang: 'tr', align: 'center'}">
                <b-spinner label="Yükleniyor..."></b-spinner>
            </Tweet>
        </div>
        <p v-if="!kayitVarMi" class="text-center
                font-weight-bold">Herhangi bir işlem bulamadık!</p>
    </div>
</template>

<script>
    import { Tweet } from 'vue-tweet-embed'

    import {OlaylariGetir} from "@/api/gonullu"

    export default {
        name: "gecmisislemler",
        data() {
            return {
                olaylar: [],
                kayitVarMi: false
            }
        },
        async created() {
            const { data } = await OlaylariGetir(this.$store.getters.kisi.id)
            this.olaylar = data
            if (this.olaylar.length !== 0)
                this.kayitVarMi = true
        },
        components: {
            Tweet
        }
    }
</script>

<style scoped>

</style>
